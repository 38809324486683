.black-transparent-btn {
  border-radius: 14px;
  // backdrop-filter: blur(14px);
  background-color: rgba(255, 255, 255, 0.12);
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }

  &_with-icon {
    svg {
      flex-shrink: 0;
      color: rgb(112, 117, 121);
    }
  }
}
