.background-position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  will-change: background-position;
  transition: cubic-bezier(0.7, 0.55, 0.42, 0.33) 0.15s background-position;
}

.background-color {
  &__type-bronze {
    background: url("../../assets/images/main-bg-bronze.png") no-repeat center
      center/cover #000;
  }
  &__type-silver {
    background: url("../../assets/images/main-bg-silver.png") no-repeat center
      center/cover #000;
  }
  &__type-gold {
    background: url("../../assets/images/main-bg-gold.png") no-repeat center
      center/cover #000;
  }
  &__type-platinum {
    background: url("../../assets/images/main-bg-platinum.png") no-repeat center
      center/cover #000;
  }
  &__type-diamond {
    background: url("../../assets/images/main-bg-diamond.png") no-repeat center
      center/cover #000;
  }
  &__type-dragon {
    background: url("../../assets/images/dragon_bg_gradient.png") no-repeat
      center center/cover #000;
  }
  &__type-fallout {
    background: url("../../assets/images/main-bg-fallout.jpg") no-repeat center
      center/cover #000;
  }
  &__type-galleon {
    background: url("../../assets/images/main-bg-galleon.jpg") no-repeat center
      center/cover #000;
  }
  &__type-pixelcoin {
    background: url("../../assets/images/skin-pixelcoin-bg.png") no-repeat
      center center/cover #000;
  }
  &__type-cookie {
    background: url("../../assets/images/main-bg-cookie.jpg") no-repeat center
      center/cover #000;
  }
  &__type-pizza {
    background: url("../../assets/images/main-bg-pizza.jpg") no-repeat center
      center/cover #000;
  }
  &__type-telegram {
    background: url("../../assets/images/main-bg-telegram.png") no-repeat center
      center/cover #000;
  }
}

.black-transparent-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.12);
  // backdrop-filter: blur(14px);
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #007aff26;
  }
}

.header-transparent-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 22px 0;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.12);
  // backdrop-filter: blur(14px);
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #007aff26;
  }
}

.main-icon-transparent-btn {
  // display: flex;
  // // justify-content: center;
  // align-items: center;
  position: relative;
  width: 100%;
  padding: 34px 0px;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.12);
  // backdrop-filter: blur(14px);
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #007aff26;
  }
}

.icon-cup-position {
  margin-right: 8px;
  width: 26px;
  height: 26px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.icon-cup {
  &__bronze {
    background-image: url("../../assets/images/Bronze-95.png");
  }
  &__silver {
    background-image: url("../../assets/images/Silver-95.png");
  }
  &__gold {
    background-image: url("../../assets/images/Gold-95.png");
  }
  &__platinum {
    background-image: url("../../assets/images/Platinum-95.png");
  }
  &__diamond {
    background-image: url("../../assets/images/Diamond-95.png");
  }
}

.icon-lighting {
  background-image: url("../../assets/images/lightning.png");
  // width: 18px;
  // height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  flex-shrink: 0;
}

.main-animation {
  opacity: 0;
  animation: main-opacity 0.25s forwards;
}

@keyframes main-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

._laurelIconReversed_xg9t0_15 {
  transform: scaleX(-1);
}

.rocket-show-animation {
  opacity: 0;
  animation: rocket-show-opacity 3s forwards;
}

@keyframes rocket-show-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rocket-close-anumation {
  opacity: 1;
  animation: rocket-close-opacity 1s forwards;
}

@keyframes rocket-close-opacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.main-tab {
  height: 66px;
  width: 100%;
  background-color: transparent;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: rgba(235, 235, 245, 0.4);
  }
  &-frens {
    border-radius: 16px 0 0 16px;
  }
  &-sparkles {
    border-radius: 0 16px 16px 0;
  }
}

.ticker {
  opacity: 0.2;
  position: absolute;
  user-select: none;
  pointer-events: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  animation: ticker-animation 5s linear infinite;
  will-change: background-position;
  transition: opacity 0.5s ease; /* Добавляем переход для плавности изменения opacity */
  transform: translate3d(0, 0, 0);
}

.background-ticker {
  &_type-turbo {
    background-image: url("../../assets/images/turbo-ticker.png"),
      url("../../assets/images/turbo-ticker.png");
    background-size: contain;
  }
  &_type-turbo1 {
    background-image: url("../../assets/images/turbo1-ticker.png"),
      url("../../assets/images/turbo1-ticker.png");
    background-size: contain;
  }
  &_type-turbo2 {
    background-image: url("../../assets/images/turbo2-ticker.png"),
      url("../../assets/images/turbo2-ticker.png");
    background-size: contain;
  }
  &_type-turbo3 {
    background-image: url("../../assets/images/turbo3-ticker.png"),
      url("../../assets/images/turbo3-ticker.png");
    background-size: contain;
  }
  &_type-turbo4 {
    background-image: url("../../assets/images/turbo4-ticker.png"),
      url("../../assets/images/turbo4-ticker.png");
    background-size: contain;
  }
  &_type-turbo5 {
    background-image: url("../../assets/images/turbo5-ticker.png"),
      url("../../assets/images/turbo5-ticker.png");
    background-size: contain;
  }
  &_type-turbo6 {
    background-image: url("../../assets/images/turbo6-ticker.png"),
      url("../../assets/images/turbo6-ticker.png");
    background-size: contain;
  }
  &_type-turbo7 {
    background-image: url("../../assets/images/turbo7-ticker.png"),
      url("../../assets/images/turbo7-ticker.png");
    background-size: contain;
  }
  &_type-turbo8 {
    background-image: url("../../assets/images/turbo8-ticker.png"),
      url("../../assets/images/turbo8-ticker.png");
    background-size: contain;
  }
}

@keyframes ticker-animation {
  0% {
    background-position: 0 0, 0 50vh;
  }
  100% {
    background-position: 0 50vh, 0 100vh;
  }
}

.shadow-string {
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.08);
}

.shadow-number {
  letter-spacing: -0.078px;
  text-shadow: 1px 1px 2px rgba(181, 103, 12, 0.25);
  font-variant-numeric: lining-nums tabular-nums;
}
