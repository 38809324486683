.styles_input__bJkBB {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-appearance: none;
  appearance: none;
  background: #181818;
  border: none;
  border-radius: 22px;
  color: #aaaaaa;
  display: block;
  // padding: 12px 0;
  padding: 8px 38px 8px 10px;
  width: calc(100% - 78px);
  outline: none;
  border-color: #f2f2f2;
}
.styles_input__bJkBB.styles_iconIncluded__2srZt {
  padding-left: 38px;
  font-size: 16px;
  line-height: 28px;
}

.style_actionHolder__grO6j {
  color: #aaaaaa;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.styles_root__aDhZI {
  display: inline-block;
  position: relative;
  width: 100%;
}

.styles_icon__tlXf7 {
  color: #aaaaaa;
  left: 8px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  z-index: 1;
}

.trade_textContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  padding: 0 12px;
}



.trade_title {
  font-family: var(--font-display-title);
  font-size: 48px;
  text-align: center;
  margin-bottom: 16px;
  line-height: 48px;
  font-weight: 700;
  display: inline-block;
  vertical-align: middle;
  width: auto;
  color: #fff;
}

// .receive-trade-btn {
//   position: fixed;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   // width: 100%;
//   padding: 14px 12px;
//   border-radius: 14px;
//   flex-grow: 1;
//   bottom: 32px;
//   left: 14px;
//   right: 14px;
//   cursor: pointer;
//   background-color: rgba(255, 255, 255, 0.12);
//   transition: background-color ease;
//   transition-duration: 0.3s;
//   &:active {
//     transition-duration: 0s;
//     background-color: rgba(0, 122, 255, 0.3);
//   }
// }

.trade-animation {
  opacity: 0; /* Начальная прозрачность */
  animation: trade-opacity 0.25s forwards; /* Название анимации и время */
}

@keyframes trade-opacity {
  from {
    opacity: 0; /* Начальное значение прозрачности */
  }
  to {
    opacity: 1; /* Конечное значение прозрачности */
  }
}

.imageCoin-trade {
  width: 20px;
  height: 20px;
  background-image: url("../../assets/images/coin-min.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  margin-left: 6px;
  margin-top: 2px;
}

.backdropConfirm-receive {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 9px 0;
  border-radius: 14px;
  background-color: #007aff;
  // width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #0661bd;
  }
}

// background-color: rgba(255, 255, 255, 0.12);
//   transition: background-color ease;
//   transition-duration: 0.3s;
//   &:active {
//     transition-duration: 0s;
//     background-color: rgba(0, 122, 255, 0.3);
//   }

// .backdropConfirm-share {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: absolute;
//   bottom: 80px;
//   left: 0;
//   right: 0;
//   padding: 13px 0;
//   border-radius: 14px;
//   background-color: rgba(255, 255, 255, 0.12);
//   // width: 100%;
//   margin-left: 16px;
//   margin-right: 16px;
//   transition: background-color ease;
//   transition-duration: 0.3s;
//   &:active {
//     transition-duration: 0s;
//     background-color: rgba(0, 122, 255, 0.3);
//   }
// }

.trade_skeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  // padding: 20px 0;
  list-style: none;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  &__wrapper {
    width: 100%;
    height: 50px;
    position: relative;
  }
}

// circle
.trade_skeleton__circle {
  position: absolute;
  border-radius: 100%;
  // left: 20px;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
}

// first line
.trade_element__box1 {
  position: absolute;
  left: 60px;
  top: 1px;
  // width: 90px;
  // height: 8px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

// second line
.trade_element__box2 {
  position: absolute;
  left: 60px;
  top: 24px;
  // width: 172px;
  // height: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.trade_element__box3 {
  position: absolute;
  left: 60px;
  bottom: -9px;
  height: 1px;
  width: 100%;
  // width: 172px;
  // height: 8px;
  background: #212121;
}

// .trade_element__box5 {
//   position: absolute;
//   right: 0px;
//   top: 30%;
//   width: 24px;
//   // height: 8px;
//   font-weight: 500;
//   color: #fff;
//   text-align: center;
// }

// .styles_root__3z53k {
//   align-items: center;
//   background: transparent;
//   box-sizing: border-box;
//   display: flex;
//   gap: 0;
//   justify-content: space-between;
//   left: 0;
//   overflow: hidden;
//   padding: 8px 12px 4px;
//   position: fixed;
//   // top: calc(var(--header_height) - var(--initial_header_height));
//   transform: translateZ(0);
//   transition: top .2s ease;
//   width: 100%;
//   z-index: 2;
// }

// .style_root__9vqgd {
//   gap: 10px;
// }

// .styles_root__aDhZI {
//   display: inline-block;
//   position: relative;
//   width: 100%;
// }

// .styles_input__bJkBB.styles_iconIncluded__2srZt {
//   padding-left: 38px;
// }

// .styles_input__bJkBB {
//   padding: 9px 38px 9px 10px;
//   padding-left: 10px;
// }

// .styles_input__bJkBB {
//   -webkit-tap-highlight-color: rgba(0,0,0,0);
//   -webkit-appearance: none;
//   appearance: none;
//   background: #181818;
//   border: none;
//   border-radius: 12px;
//   caret-color: #007aff;
//   color: #fff;
//   display: block;
//   padding: 8px 38px 8px 10px;
//   width: 100%;
// }

// .styles_callout__z6vfq {
//   font-size: 16px;
//   line-height: 22px;
// }

// .styles_icon__tlXf7 {
//   color: #aaa;
//   left: 8px;
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   width: 24px;
//   z-index: 1;
// }

// .styles_body__HMFd- {
//   font-size: 17px;
//   line-height: 22px;
// }
