.join-squad-btn {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background-color: rgba(0, 122, 255, 1);
  flex-grow: 1;
  bottom: 14px;
  left: 14px;
  right: 14px;
  cursor: pointer;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: rgba(0, 122, 255, 0.4);
  }
}

.join-squad-loader {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 2.6px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: task-rotation 1.5s linear infinite;
}

@keyframes task-rotation {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
