.fren-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
  box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
  flex-grow: 1;
  position: fixed;
  bottom: 16px;
  left: 14px;
  right: 14px;
  cursor: pointer;
}

.coin-min-icon {
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/coin-min.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.crown-icon {
  margin-left: 24px;
  margin-right: 8px;
  width: 24px;
  height: 24px;
  background-image: url("../../assets/images/crown.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.crown-icon {
  margin-left: 24px;
  margin-right: 8px;
  margin-top: -4px;
  width: 24px;
  height: 24px;
  // background-image: url("../../assets/images/influencer.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.vertical-line {
  margin-left: 24px;
  height: 24px;
  width: 1px;
  background-color: rgba(235, 235, 245, 0.06);
}

.fren-page {
  &__header-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px 12px;
    width: 100%;
    margin-top: 23px;
  }
  &__center {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 112px 12px;
    margin-top: 12px;
  }
  &__center-content {
    display: flex;
    align-items: center;
  }
  &__list {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 45px 12px;
    margin-top: 14px;
  }
  &__list-content {
    display: flex;
    align-items: center;
  }
  &__header-btn-content {
    display: flex;
    align-items: center;
  }
}

.black-transparent {
  border-radius: 16px;
  backdrop-filter: blur(24px);
  background: rgba(255, 255, 255, 0.12);

  &_with-icon {
    svg {
      flex-shrink: 0;
      color: rgb(112, 117, 121);
    }
  }
}

._coin-icon {
  // margin-right: 24px;
  width: 50px;
  height: 50px;
  background-image: url("../../assets/images/moneta-small.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.fren-page {
  &__bear-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__bear-icon {
    font-size: 48px;
  }
}

.qV5z {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.rRnL {
  position: fixed;
  top: 20px;
  left: 100px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.FLHU {
  position: fixed;
  top: 50px;
  left: 100px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

.moneta-small-icon-position {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 58px;
  height: 58px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.mzQl {
  position: fixed;
  top: 100px;
  left: 100px;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.i4hs {
  position: fixed;
  top: 130px;
  left: 100px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}

// .prem-icon-position {
//   position: fixed;
//   top: 100px;
//   left: 20px;
//   width: 58px;
//   height: 58px;
//   background-repeat: no-repeat;
//   background-size: contain;
//   background-color: transparent;
// }

.fren-icon {
  &__coin {
    background-image: url("../../assets/images/coin-min.svg");
  }
  // &__prem {
  //   background-image: url("../../assets/images/prem.png");
  // }
}

.open-details-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background-color: transparent;
  flex-grow: 1;
  bottom: 8px;
  left: 14px;
  right: 14px;
  cursor: pointer;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
}

.fren-animation {
  opacity: 0; /* Начальная прозрачность */
  animation: fren-opacity 0.5s forwards; /* Название анимации и время */
}

@keyframes fren-opacity {
  from {
    opacity: 0; /* Начальное значение прозрачности */
  }
  to {
    opacity: 1; /* Конечное значение прозрачности */
  }
}


.fren_skeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  list-style: none;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.12);
  &__wrapper {
    width: 100%;
    height: 50px;
    position: relative;
  }
}

// circle
.fren_skeleton__circle {
  position: absolute;
  border-radius: 100%;
  left: 20px;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
}

.fren_skeleton__pulse {
  position: absolute;
  left: 20px;
  height: 12px;
  width: 12px;
  background-color: #000;
  border-radius: 50%;
  margin-top: 34px;
  margin-left: 30px;
  border: 2px solid #000;
}

// first line
.fren_element__box1 {
  position: absolute;
  left: 80px;
  top: 1px;
  // width: 90px;
  // height: 8px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

// second line
.fren_element__box2 {
  position: absolute;
  left: 80px;
  top: 24px;
  // width: 172px;
  // height: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

// little line
.fren_element__box3 {
  position: absolute;
  left: 20px;
  top: 25%;
  width: 24px;
  // height: 8px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.fren_element__box4 {
  position: absolute;
  right: 36px;
  top: 32%;
  width: 24px;
  // height: 8px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.fren_element__box5 {
  position: absolute;
  right: 10px;
  top: 30%;
  width: 24px;
  // height: 8px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}

.fren-cup-position {
  margin-top: 6px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

// .fren-pulse-position {

// }