.progress-bar {
  border: none;
  height: 16px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff3;
  width: 100%;
  flex-grow: 1;
}
.progress-bar-line {
  height: 16px;
  border-radius: 20px;
  width: 100%;
  transition: width 0.45s ease 0.2s, opacity 0.45s ease 0.2s;
  opacity: 0;
  animation: progress-bar-opacity 1s forwards;
  will-change: opacity, width, transition;
}

// .progress-bar-opacity {
//   from 
// }

@keyframes progress-bar-opacity {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

.progress-bar-color {
  &_type-bronze {
    background: linear-gradient(270deg, #fefbe9 0%, #ff9a00 100%);
  }
  &_type-silver {
    background: linear-gradient(270deg, #eff9ff 0%, #4a57a7 100%);
  }
  &_type-gold {
    background: linear-gradient(270deg, #fffcd2 0%, #f6c35e 100%);
  }
  &_type-platinum {
    background: linear-gradient(270deg, #dae3ff 0%, #696c7f 100%);
  }
  &_type-diamond {
    background: linear-gradient(270deg, #9ffeff 0%, #2792e2 100%);
  }
  &_type-cookie {
    background:linear-gradient(270deg,#86DBFF 0%,#0076AA 100%)
  }
  &_type-galleon {
    background:linear-gradient(270deg,#fffcd2,#f6c35e)
  }
  &_type-fallout {
    background:linear-gradient(270deg,#9ffeff,#2792e2)
  }
  &_type-dragon {
    background: linear-gradient(270deg, #fffcd2 0%, #f6c35e 100%);
  }
  &_type-pixelcoin {
    background:linear-gradient(270deg,white 0%, rgb(51,49,212) 100%)
  }
  &_type-telegram {
    background:linear-gradient(270deg,white 0%, rgb(36,161,222) 100%)
    // background:linear-gradient(90deg,rgb(250,251,90) 0%,rgb(108,226,157) 100%)
  }
  &_type-turbo {
    background: linear-gradient(270deg, #f6c35e 0%, #ff7b1c 100%);
  }
  &_type-time {
    background: linear-gradient(270deg, #24fc03 0%, #fc7703 80%);
  }
}