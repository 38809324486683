.top-squad-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background-color: rgba(0, 122, 255, 1);
  flex-grow: 1;
  bottom: 74px;
  left: 14px;
  right: 14px;
  cursor: pointer;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: rgba(0, 122, 255, 0.4);
  }
}

.bottom-squad-btn {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  flex-grow: 1;
  bottom: 14px;
  left: 14px;
  right: 14px;
  cursor: pointer;
  transition: background-color ease;
  transition-duration: 0.3s;
  &-active {
    background-color: rgba(0, 122, 255, 1);
    &:active {
      transition-duration: 0s;
      background-color: rgba(0, 122, 255, 0.4);
    }
  }
  &-gray {
    background-color: rgba(255, 255, 255, 0.12);
    &:active {
      transition-duration: 0s;
      background-color: rgba(0, 122, 255, 0.3);
    }
  }
}