.squads-animation {
    opacity: 0; /* Начальная прозрачность */
    animation: squads-opacity 0.5s forwards; /* Название анимации и время */
  }
  
  @keyframes squads-opacity {
    from {
      opacity: 0; /* Начальное значение прозрачности */
    }
    to {
      opacity: 1; /* Конечное значение прозрачности */
    }
  }

.disco-big-icon {
  background-image: url("../../assets/images/disco.png");
  background-size: contain;
}

.squads-transparent-div {
  position: relative;
  border-radius: 14px;
  // backdrop-filter: blur(14px);
  background: rgba(255, 255, 255, 0.12);
  &_first {
    padding: 51px;
    margin-top: 23px;
  }
  &_second {
    padding: 212px 0px;
    margin-top: 14px;
  }
}

.squads-transparent-btn {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 70px;
  width: 100%;
  transition: background-color ease;
  transition-duration: 0.3s;
  &_first {
    margin-top: 6px;
    border-radius: 4px;
  }
  &_second {
    margin-top: 6px;
    border-radius: 4px 4px 0px 0px;
  }
  &_third {
    margin-top: 74px;
  }
  &_fourth {
    margin-top: 142px;
  }
  &_fifth {
    margin-top: 210px;
  }
  &_sixth {
    margin-top: 278px;
  }
  &_seventh {
    margin-top: 346px;
    border-radius: 0px 0px 4px 4px;
  }
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
}


.squads_skeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  list-style: none;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.12);
  &__wrapper {
    width: 100%;
    height: 50px;
    position: relative;
  }
}

// circle
.squads_skeleton__circle {
  position: absolute;
  border-radius: 32%;
  left: 20px;
  width: 48px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
}

// first line
.squads_element__box1 {
  position: absolute;
  left: 80px;
  top: 1px;
  // width: 90px;
  // height: 8px;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

// second line
.squads_element__box2 {
  position: absolute;
  left: 80px;
  top: 24px;
  // height: 8px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.squads_element__box3 {
  position: absolute;
  right: 10px;
  top: 30%;
  width: 24px;
  // height: 8px;
  font-weight: 500;
  color: #fff;
  text-align: center;
}