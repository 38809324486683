._loader_1urhx_1 {
  width: 22px;
//   min-width: 220px;
  height: 22px;
//   min-height: 220px;
  border: 2.6px solid rgba(235, 235, 245, 0.18);
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: _rotation_1urhx_1 1.5s linear infinite;
}
@keyframes _rotation_1urhx_1 {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  width: 100px;
  height: 100px;
}

._cooldown_2szzu_77 {
  width: 300px;
  height: 300px;
  transform: rotateY(-180deg) rotate(-90deg);
}
._cooldownContainer_2szzu_82 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  height: 122vh;
  animation: showCircleScale 0.5s forwards;
}

@keyframes showCircleScale {
  from {
    transform: scale(0.8) translateY(20px);
  }
  to {
    transform: scale(1) translateY(0);
  }
}

._cooldownNumber_2szzu_89 {
  position: absolute;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // margin: auto;
  color: #ffffff59;
  font-size: 56px;
  font-weight: 800;
  // line-height: 290px;
  margin-top: -20px;
}
._cooldownCircle_2szzu_101 {
  stroke-dasharray: 900px;
  stroke-dashoffset: 900px;
  stroke-linecap: round;
  stroke-width: 17px;
  stroke: #ffffff59;
  fill: none;
  animation: _countdown_2szzu_1 10s linear forwards;
}

@keyframes _countdown_2szzu_1 {
  0% {
    stroke-dashoffset: 900px;
  }
  to {
    stroke-dashoffset: 0;
  }
}
