.send-e1Tl {
  --font-size: 44px;
  --amount-height: 56px;
  padding: 12px 4px;
}
.send-unbr {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.send-_Nat {
  max-width: 100%;
}

.send-KU0V {
  // font-family: Roboto, system-ui, sans-serif;
  font-size: 15px;
  line-height: 18px;
}

.send-dv_2 {
  display: block;
}

.send-PW2J {
  align-items: center;
  color: #fff;
  display: flex;
  gap: 4px;
}

.send-TlJ_, .send-tt_o {
  align-items: center;
  display: flex;
}
.send-tt_o {
  max-width: 100%;
}

.send-spEm {
  flex-shrink: 0;
  margin-inline-end: 8px;
}

.send-PuF7 {
  background-color: #8774e1;
}
.send-vilv {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
}

.send-vilv svg {
  display: block;
  height: 100%;
  width: 100%;
}

.send-r68T {
  // font-family: Roboto,system-ui,sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

.send-TlJ_ {
  overflow: hidden;
  white-space: nowrap;
}
.send-TlJ_, .send-tt_o {
  align-items: center;
  display: flex;
}

.send-TlJ_ span {
  flex-shrink: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.send-TlJ_ b:not(:first-child) {
  margin-inline-start: 4px;
}
.send-TlJ_ b {
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.send-CpWq {
  align-items: center;
  display: flex;
  gap: 20px;
  width: 100%;
}

.send-_m3Z {
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.send-JUMA {
  overflow: hidden;
}

.send-B7pe {
  align-items: baseline;
  display: flex;
  font-feature-settings: "tnum" on,"lnum" on;
  gap: 6px;
  height: 64px;
  position: relative;
}

.send-EolD {
  font-family: var(--font-display-send);
  // font-family: Roboto,sans-serif;
}

.send-JdkE {
  font-size: 56px;
  font-weight: 700;
  line-height: 64px;
}

.send-_R84, .send-gw_6 {
  font-family: inherit;
  font-feature-settings: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}
.send-gw_6 {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  color: transparent;
  left: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 0;
  // width: 100%;
}

.send-_R84, .send-gw_6 {
  color: #fff;
  font-family: inherit;
  font-feature-settings: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.send-_R84 {
  display: block;
  pointer-events: none;
}

.send-K3Zv {
  // font-family: Roboto,sans-serif;
  // font-size: 28px;
  // line-height: 40px;
  font-family:ui-rounded,sans-serif;
  font-feature-settings:"tnum" on,"lnum" on;
  font-size:40px;
  line-height:64px
}
.send-TPHb {
  align-items: center;
  color: #aaa;
  display: flex;
  font-weight: 700;
}

.send-_m3Z::after {
  animation: send-rR7R 1.5s linear infinite;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity .3s ease;
}

.send-_m3Z.send-rR7R:after {
  opacity:.5
}
@keyframes send-rR7R {
  0% {
    transform:translateX(-100%)
  }
  50% {
    transform:translateX(100%)
  }
  to {
    transform:translateX(-100%)
  }
}

.send-RdIr {
  // font-family: Roboto,system-ui,sans-serif;
  font-size: 15px;
  line-height: 18px;
}
.send-RdIr, .send-g4zH {
  font-style: normal;
  font-weight: 400;
}
.send-xqwU {
  color: #aaa;
}

.send-NCgc {
  color: #ff595a;
}


@keyframes D3GM {
  10%,
  90% {
    transform:translate3d(-1px,0,0)
  }
  20%,
  80% {
    transform:translate3d(2px,0,0)
  }
  30%,
  50%,
  70% {
    transform:translate3d(-4px,0,0)
  }
  40%,
  60% {
    transform:translate3d(4px,0,0)
  }
}

.D3GE {
  animation:D3GM .5s cubic-bezier(.36,.07,.19,.97) both;
  transform:translateZ(0)
}









.send-KU0V,
.send-_8Vj {
  color: #fff;
  font-style: normal;
  font-weight: 400;
}

.send-Z5dC {
  background: transparent;
  border-top: 0.33px solid #000;
  left: 0;
  overflow: hidden;
  position: fixed;
  top: 100vh;
  transform: translateY(-100%);
  width: 100%;
  z-index: 2;
}

.send-Z5dC::before {
  background: #212121;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  transform-origin: bottom left;
  transform-origin: top left;
  width: 100%;
}

.send-Bcb3 {
  --cell-avatar-inline-spacing: 14px 11px;
  --cell-text-inline-spacing: 20px 11px;
  --cell-radio-inline-spacing: 24px 16px;
  --cell-checkbox-inline-spacing: 28px 25px;
  --cell-icon-inline-spacing: 21px 16px;
  --cell-square-icon-inline-spacing: 20px 16px;
  flex-shrink: 0;
}

.send-RkvK {
  margin-inline: 14px 11px;
  margin-top: 4px;
}

.send-YrPC {
  margin-inline-start: 0;
  padding-inline-end: 20px;
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  max-width: 100%;
  min-width: 0;
  position: relative;
}

.send-jOCs.send-NXXw {
  align-items: flex-start;
}

.send-jOCs.send-If_N {
  flex-direction: column-reverse;
}

.send-TYgZ {
  gap: 2px;
  min-height: 50px;
  padding-block: 6px;
}

.send-jOCs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin-inline: var(--cell-text-inline-spacing);
  max-width: 100%;
  min-width: 0;
}

.send-Vysp {
  overflow: visible;
  white-space: pre-line;
}

.send-kzP3 {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.send-CF5m {
  font-weight: 500;
}

.send-IqPa {
  --skeleton-height: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #fff;
}

.send-Bgj6 {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.send-KbDJ,
.send-UBSx {
  --skeleton-height: 12px;
  font-size: 15px;
  line-height: 18px;
  color: #aaa;
}

.send-UBSx {
  font-weight: 400;
}
