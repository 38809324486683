.pulse {
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #42a0ff;
  margin-right: 6px;
  &::before {
    position: absolute;
    content: "";
    display: block;
    width: 300%;
    height: 300%;
    box-sizing: border-box;
    margin-left: -100%;
    margin-top: -100%;
    border-radius: 45px;
    background-color: #42a0ff;
    animation: animation-pulse 1.8s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
}

@keyframes animation-pulse {
  0% {
    transform: scale(0.33);
  }
  80%,
  to {
    opacity: 0;
  }
}
