
$background-color: rgba(255, 255, 255, 0.12);
$color: rgba(255, 255, 255, 0.04);
$shine-color: rgba(255, 255, 255, 0.3);
$animation-duration: 2s;
$avatar-offset: 52 + 16;
$little-offset: 32;

@mixin background-gradient {
    background-image: linear-gradient(90deg, $background-color 0px, $shine-color 40px, $background-color 80px);
    background-size: 600px;
}

@mixin sp($n: 1) {
  position: absolute;
  z-index: $n;
  border-radius: 12px;
  @include background-gradient;
}

.skeleton {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  list-style: none;
  border-radius: 20px;
  width: 100%;
  height: 100%;
  background-color: $background-color;
  &__wrapper {
    width: 100%;
    height: 50px;
    position: relative;
  }
}

// circle
.skeleton__circle {
  @include sp();
  border-radius: 100%;
  left: 60px;
  top: 1px;
  width: 48px;
  height: 48px;
  background-color: $color;
  animation: shine-avatar $animation-duration infinite linear;
}

// first line
.element__box1 {
  @include sp();
  left: 120px;
  top: 10px;
  width: 90px;
  height: 8px;
  background-color: $color;
  animation: shine-lines $animation-duration infinite linear;
}

// second line
.element__box2 {
  @include sp();
  left: 120px;
  top: 32px;
  width: 172px;
  height:8px;
  background-color: $color;
  animation: shine-lines $animation-duration infinite linear;
}

// little line
.element__box3 {
  @include sp();
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 8px;
  background-color: $color;
  animation: little-line $animation-duration infinite linear;
}

@keyframes shine-lines {
    0% {
        background-position: -100px;
    }
    
    40%, 100% {
        background-position: 240px;
    }
}

@keyframes shine-avatar {
    0% {
        background-position: -100px + $avatar-offset;
    }
    
    40%, 100% {
        background-position: 140px + $avatar-offset;
    }
}

@keyframes little-line {
    0% {
        background-position: -100px + $little-offset;
    }
    
    40%, 100% {
        background-position: 140px + $little-offset;
    }
}