:root {
  // --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui,
  //   "Helvetica Neue", Helvetica, Arial, sans-serif;
  // --font-display1: ui-rounded, sans-serif;
  // --font-display-ios-rounded1: "SF Pro Rounded", -apple-system, "SF Pro Text",
  //   "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  // --font-display-title: "ClashDisplay-Semibold", -apple-system, "SF Pro Text",
  //   "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-title: "ClashDisplay-Semibold", -apple-system, "SF Pro Text", "SF UI Text", system-ui, "Helvetica Neue", Helvetica, Arial, sans-serif;
  --palette-white: #fff;
  --page-control-duration-time: 2.8s;
  --k-device-pixel-ratio: 2;
  --body-padding: 16px;
  --safe-area-left: 16px;
  --safe-area-right: 16px;
  --palette-dark-4: #efeff3;
  --palette-dark-5: #d4d4d4;
  --palette-dark-90: #1c1c1e;
  --palette-dark: #000;
  --palette-transparent-grey-25: rgba(84, 84, 88, 0.25);
  --palette-transparent-grey-65: rgba(84, 84, 88, 0.65);
  --palette-transparent-grey-18: rgba(116, 116, 128, 0.18);
  --palette-transparent-grey-8: rgba(116, 116, 128, 0.08);
  --palette-transparent-white-05: rgba(235, 235, 245, 0.05);
  --palette-transparent-white-08: rgba(255, 255, 255, 0.08);
  --palette-transparent-white-12: rgba(255, 255, 255, 0.12);
  --palette-transparent-white-18: rgba(235, 235, 245, 0.18);
  --palette-transparent-white-30: rgba(235, 235, 245, 0.3);
  --palette-transparent-white-60: rgba(235, 235, 245, 0.6);
  --palette-transparent-white-80: rgba(235, 235, 245, 0.8);
  --palette-transparent-dark-05: rgba(0, 0, 0, 0.05);
  --palette-transparent-dark-08: rgba(0, 0, 0, 0.08);
  --palette-transparent-dark-80: rgba(45, 45, 45, 0.8);
  --palette-transparent-dark-60: rgba(60, 60, 67, 0.6);
  --palette-transparent-dark-36: rgba(60, 60, 67, 0.36);
  --palette-transparent-dark-30: rgba(60, 60, 67, 0.3);
  --palette-transparent-dark-18: rgba(60, 60, 67, 0.18);
  --palette-brand-dark: #007aff;
  --palette-brand-light: #0a84ff;
  --palette-red-light: #ff3b30;
  --palette-red-dark: #ff4530;
  --palette-green-light: #34c759;
  --palette-green-dark: #30d158;
  --elevation-light-1: 0px 10px 60px rgba(0, 0, 0, 0.1);
  --elevation-dark-1: 0px 10px 60px rgba(0, 0, 0, 0.7);
  --tg-theme-button-color: red;
  --notcoin-orange: #feb803;
  --pixelcoin-orange: #fdb927;
}

// @media (prefers-reduced-motion: reduce) {
//   *, ::before, ::after
// {
//     animation-duration: .01ms !important;
//     animation-iteration-count: 1 !important;
//     transition-duration: .01ms !important;
//     scroll-behavior: auto !important;
//   }
// }

html {
  height: 100%;
}

.apple {
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-send: ui-rounded, sans-serif;
  // --font-display-title: -apple-system, serif;
  // --font-display: ui-rounded, sans-serif;
}

.material {
  --font-display: -apple-system, "SF Pro Text", "SF UI Text", system-ui,
    "Helvetica Neue", Helvetica, Arial, sans-serif;
  --font-display-send: Roboto, sans-serif;
  // --font-display-title: Roboto, serif;
  // --font-display: Roboto,system-ui,sans-serif;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: var(--font-display), sans-serif;
  font-weight: 400;
  color: var(--color-text-primary);
  background-color: #000;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  margin: 0;
  -webkit-overflow-scrolling: touch !important;
  user-select: none;
  // outline: 0;
}

.scrolling {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-touch-callout: none;
  user-select: none;
}

.container {
  padding: 0 14px;
  margin: 0 auto;
}

.position-relative {
  position: relative;
}

.page {
  position: relative;
  &__content {
    min-height: 100vh;
    padding: 16px 0;
  }
}

button {
  cursor: pointer;
  border: none;
  background: transparent;
  font-family: var(--font-display), sans-serif;
}

ul {
  padding: 0;
}

a {
  -webkit-user-drag: none;
  text-decoration: none;
}

.background-influencer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("./assets/images/bg-influencer.png") no-repeat center
    center/cover #000;
  will-change: background-position;
  transition: cubic-bezier(0.7, 0.55, 0.42, 0.33) 0.15s background-position;
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.background-yellow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  transition: opacity 0.35s ease;
  filter: blur(5px);
  // -webkit-filter: blur(5px);
}

.theme-yellow {
  background-image: url("./assets/images/bg-yellow.png");
  background-position: center bottom;
}

.background-compete {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  filter: blur(5px);
  -webkit-filter: blur(5px);
  // transform: rotate(180deg);
}

.theme-compete {
  // background-image: url("./assets/images/soon_bg.jpeg");
  background-image: url("./assets/images/bg-influencer.png");
}

.balance-icon {
  margin-right: 12px;
  width: 40px;
  height: 40px;
  background-image: url("./assets/images/coin-min.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  margin-top: 2px;
}

.penny-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("./assets/images/penny.png");
}

.icon-coin-small {
  background-image: url("./assets/images/icon-coin-small.png");
  background-repeat: no-repeat;
  background-size: contain;
}

.score-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  background-image: url("./assets/images/coin-min.svg");
}

.rocket {
  position: absolute;
  height: 80px;
  width: 80px;
  user-select: none;
  z-index: 10;
  &_child-1 {
    top: 0;
    left: 0;
    margin-top: 112px;
  }
  &_child-2 {
    top: 0;
    right: 0;
    margin-top: 46px;
    margin-right: 40%;
  }
  &_child-3 {
    top: 0;
    right: 0;
    margin-top: 153px;
    margin-right: 34px;
  }
  &_child-4 {
    bottom: 0;
    left: 0;
    margin-bottom: 113px;
    margin-left: 23px;
  }
  &_child-5 {
    bottom: 0;
    left: 0;
    margin-bottom: 44px;
    margin-left: 55%;
  }
  &_child-6 {
    bottom: 0;
    right: 0;
    margin-bottom: 132px;
    margin-right: 8px;
  }
}

.B0WY {
  display: flex;
}

.PQc6 {
  color: #fff;
}

.Jh2w {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Li2p {
  display: flex;
  align-items: center;
}

.Z5dC {
  cursor: pointer;
}

.icon-shrink {
  flex-shrink: 0;
  &__arrow {
    color: rgba(235, 235, 245, 0.4);
  }
}

.join-squad-slide-left {
  animation: slide-down-enter-opacity 0.25s ease,
    join-squad-slide-left-animation 1s ease;
}

.join-squad-slide-right {
  animation: slide-down-enter-opacity 0.25s ease,
    join-squad-slide-right-animation 1s ease;
}

@keyframes join-squad-slide-left-animation {
  from {
    transform: translateX(-100px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes join-squad-slide-right-animation {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0);
  }
}

.about-slide-down {
  animation: slide-down-enter-opacity 0.25s ease,
    slide-down-enter-animation 0.4s ease;
}

.slide-down-enter {
  animation: slide-down-enter-opacity 0.25s ease,
    slide-down-enter-animation 1s ease;
}

.league-opacity {
  animation: slide-down-enter-opacity 1.5s ease;
}

.join-squad-down-enter {
  animation: slide-down-enter-opacity 1s ease,
    slide-down-enter-animation 1s ease;
}

@keyframes slide-down-enter-animation {
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-down-enter-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-enter-opacity {
  animation: main-opacity 0.6s ease;
}

@keyframes main-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-enter-scale {
  animation: main-scale 0.4s ease;
}

@keyframes main-scale {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.JG3Z {
  display: inline;
}

.R35h {
  color: var(--pixelcoin-orange);
}

.yhE4 {
  position: fixed;
  user-select: none;
  pointer-events: none;
}

.none {
  opacity: 0.5;
  pointer-events: none;
}

.fO6H {
  text-align: center;
}

.Rs1m {
  padding-left: 2px;
}

.iXk6 {
  padding-right: 2px;
}

.IueR {
  margin-top: 2px;
}

.lDtF {
  padding-top: 2px;
}

.Iu3d {
  padding-top: 3px;
}

.Ut3c {
  margin-right: 4px;
}

.Hexc {
  margin-top: 4px;
}

.Udpa {
  margin-top: 5px;
}

.IJx3 {
  margin-top: 6px;
}

.bv0r {
  margin-right: 6px;
}

.TtWO {
  margin-right: 8px;
}

.IYsx {
  margin-top: 8px;
}

.bgqq {
  margin-top: 12px;
}

.JGV3 {
  margin-top: 16px;
}

.IYww {
  margin-left: 14px;
}

.Ug3o {
  margin-top: 14px;
}

.Iuyw {
  margin-top: 16px;
}

.I7pd {
  margin-top: 24px;
}

.uY4S {
  margin-bottom: 72px;
}

.uFwS {
  padding-bottom: 186px;
}

.yyfJ {
  font-weight: 600;
}

.icon-shrink {
  flex-shrink: 0;
}

.kUe3 {
  font-size: 48px;
  font-weight: 700;
  color: #fff;
}

.Jh3c {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-display-ios-rounded);
  flex-flow: row nowrap;
  font-size: 32px;
  font-weight: 700;
  // line-height:64px;
  font-style: normal;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  color: #fff;
}

.UIn3 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--font-display-ios-rounded);
  flex-flow: row nowrap;
  font-size: 48px;
  font-weight: 800;
  line-height: 64px;
  font-style: normal;
  text-align: center;
  font-variant-numeric: lining-nums tabular-nums;
  color: #fff;
}

.Po2s {
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  margin-right: 10px;
}

.Igw3 {
  flex-grow: 1;
  position: fixed;
  bottom: 56px;
  left: 14px;
}

.JI3s {
  position: absolute;
  bottom: 0px;
  left: 14px;
  right: 14px;
  margin-bottom: 136px;
}

// .E3dv {
//   flex-grow: 1;
//   position: fixed;
//   bottom: 66px;
//   right: 14px;
// }

.E3dv {
  flex-grow: 1;
  position: fixed;
  bottom: 110px;
  right: 14px;
  width: 100%;
}

.KI3d {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  margin-right: 12px;
}

.EWRO {
  display: flex;
  color: var(--palette-dark-5);
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
}

.XVNe {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}

.xMOh {
  display: flex;
  gap: 4px;
  padding-block: 3px;
  padding-inline: 18px;
}

// .Wu_F {
//   color: #fff;
//   font-size: 17px;
//   font-weight: 600;
//   margin-right: 10px;
// }

.qLoL {
  color: var(--palette-brand-light);
  font-size: 16px;
  font-weight: 600;
  transition: color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    color: #0661bd;
  }
}

.Wt6t {
  padding-top: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.BVKK {
  padding-top: 6px;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.j4YN {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.aZVu {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
}

.QPvY {
  display: flex;
  padding-top: 40px;
  padding-left: 18px;
}

.jbux {
  display: flex;
  padding-top: 44px;
  padding-left: 18px;
}

.NYs_ {
  display: flex;
  padding-top: 28px;
  padding-left: 18px;
}

.bvrx {
  display: flex;
  padding-top: 44px;
  padding-left: 18px;
}

.Tfj_ {
  display: flex;
  padding-top: 28px;
  padding-left: 18px;
}

.bz8O {
  display: flex;
  padding-top: 28px;
  padding-left: 18px;
}

.WV8R {
  width: 48px;
  height: 48px;
}

.JHs0 {
  padding: 2px 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 16px;
  color: #fff;
}

.Jye1 {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  margin-top: 42px;
}

.TYw1 {
  display: flex;
  margin-top: 2px;
  color: #fff;
}

.Uye4 {
  color: var(--palette-transparent-white-80);
  font-size: 16px;
  font-weight: 400;
  margin-top: 26px;
}

.BeIu {
  color: #fff;
  font-size: 48px;
  font-weight: 700;
}

.KH5k {
  color: #0a84ff;
  font-size: 15px;
  font-weight: 600;
  transition: color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    color: #0661bd;
  }
}

.hcoi {
  color: #0a84ff;
  font-size: 16px;
  font-weight: 600;
  margin-top: 6px;
  transition: color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    color: #0661bd;
  }
}

.KU2l {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.JIYc {
  text-align: center;
}

.Iuw4 {
  color: #d4d4d4;
  font-weight: 500;
  margin-left: 6px;
}

.Gyr1 {
  text-align: left;
  font-size: 17px;
  font-weight: 500;
  color: #d4d4d4;
}

.YRia {
  display: flex;
  text-align: left;
  padding-top: 4px;
  font-size: 15px;
  font-weight: 600;
  color: var(--notcoin-orange);
}

.In2x {
  display: flex;
  text-align: left;
  padding-top: 4px;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
}

.HeOi {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}

.Ip3o {
  font-size: 16px;
  font-weight: 600;
  margin-left: 2px;
  margin-right: 6px;
  color: var(--pixelcoin-orange);
}

.Iy4o {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  // margin-top: 10px;
}

.OUwl {
  font-size: 22px;
  font-weight: 600;
  margin-top: 28px;
  color: #fff;
}

.OIe4 {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  color: rgba(235, 235, 245, 0.6);
}

.UsL6 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 28px;
  color: var(--pixelcoin-orange);
}

.iUeR {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  margin-right: 6px;
}

._QtZ {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
}

.P8Ey {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -18px;
}

.og0c {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.Zo7S {
  font-size: 16px;
  font-weight: 600;
  color: rgba(235, 235, 245, 0.5);
}

.Mfze {
  height: 16px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff3;
  margin-top: 14px;
}

.Iued {
  height: 16px;
  border-radius: 20px;
  overflow: hidden;
  background-color: transparent;
  margin-top: 14px;
}

.wDXF {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

// .UQdA {
//   width: 100px;
//   height: 100px;
//   cursor: pointer;
// }

.UQdA {
  width: 80px;
  height: 80px;
  cursor: pointer;
}

.NHDE {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 22px;
}

.lGlN {
  font-size: 17px;
  font-weight: 400;
  color: #fff;
}

.LUhj {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-right: 10px;
}

// .Lbr3 {
//   width: 120px;
//   height: 120px;
//   cursor: pointer;
// }

.Lbr3 {
  width: 110px;
  height: 110px;
  cursor: pointer;
}

.Ku3c {
  font-size: 100px;
  cursor: pointer;
}

// .hUYa {
//   width: 48px;
//   height: 48px;
//   cursor: pointer;
// }

.hUYa {
  // width: 38px;
  // height: 38px;
  font-size: 38px;
  cursor: pointer;
}

.UY49 {
  width: 36px;
  height: 36px;
}

.IKJH {
  width: 48px;
  height: 48px;
}

.ciU3 {
  font-size: 36px;
}

.OdfO {
  font-size: 72px;
}

.sGES {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.IYES {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
}

.m72u {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 18px;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.uDr2 {
  display: "flex";
  justify-content: center;
  align-items: center;
  color: #ffffffbf;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.ssBa {
  padding: 14px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
  box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 32px;
}

.ccBa {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
  box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
  flex-grow: 1;
  cursor: pointer;
  margin-top: 24px;
  margin-bottom: 32px;
}

.JUcV {
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
  box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
  flex-grow: 1;
  cursor: pointer;
  margin-top: 24px;
  // margin-bottom: 32px;
}

.GXa2 {
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  margin-right: 10px;
}

.sPqV {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 14px 12px;
  border-radius: 14px;
  background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
  box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
  margin-top: 24px;
}

.hhyK {
  width: 115px;
  height: 115px;
  cursor: pointer;
}

.JGew {
  width: 100px;
  height: 100px;
}

.IfNG {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-top: 22px;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
}

.nw2j {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffffbf;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.iosW {
  margin-top: 24px;
  margin-left: 18px;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
}

.Gc12 {
  margin-top: 70px;
  margin-left: 18px;
  font-size: 32px;
  font-weight: 700;
  color: #fff;
}

.z6B6 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tVxl {
  fill: #fff;
}

.I3cl {
  fill: rgba(235, 235, 245, 0.6);
}

.Y_4u {
  text-align: left;
  margin-top: 30px;
  margin-left: 18px;
  font-size: 18px;
  color: rgba(235, 235, 245, 0.5);
}

.I72s {
  font-size: 110px;
}

.xLmh {
  margin-top: 14px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(235, 235, 245, 0.3);
}

.GWMq {
  position: absolute;
  font-size: 110px;
  margin-top: -44px;
}

.JKpY {
  position: absolute;
  height: 110px;
  width: 110px;
  margin-top: -44px;
}

.Ihcq {
  position: absolute;
  height: 100px;
  width: 100px;
  margin-top: -70px;
  margin-left: -50px;
  cursor: pointer;
}

// .Ihcq {
//   position: absolute;
//   height: 110px;
//   width: 110px;
//   margin-top: -44px;
//   cursor: pointer;
// }

.I5v4 {
  position: absolute;
  font-size: 100px;
  margin-top: -44px;
  cursor: pointer;
}

.OCW2 {
  position: absolute;
  white-space: nowrap;
  // margin-top: 65px;
  margin-top: 130px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(235, 235, 245, 0.3);
}

.lgqO {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Sns9 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 130px 12px;
  margin-top: 14px;
  border-radius: 16px;
  // backdrop-filter: blur(14px);
  background: rgba(255, 255, 255, 0.12);
}

.IY3l {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 130px 12px;
  margin-top: 14px;
  border-radius: 14px;
  // backdrop-filter: blur(14px);
  background: rgba(255, 255, 255, 0.12);
}

.UIx1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 130px 12px;
  margin-top: 16px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.12);
}

.vBwQ {
  width: 15px;
  height: 15px;
}

.BwVs {
  width: 17px;
  height: 17px;
}

.nnot {
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}

.UEiU {
  display: flex;
  padding-top: 16px;
  padding-left: 16px;
}

.JfMo {
  display: flex;
  padding-top: 16px;
  padding-left: 4px;
}

.tjl5 {
  position: absolute;
  top: 0px;
}

.fGjD {
  display: flex;
  position: relative;
  // padding: 112px 12px;
  padding: 72px 12px;
  margin-top: 12px;
}

.JHec {
  display: flex;
  position: relative;
  padding: 78px 0;
  margin-top: 24px;
}

.IU4c {
  display: flex;
  position: relative;
  padding: 108px 0;
  margin-top: 24px;
}

.rEQ9 {
  display: flex;
  padding-top: 30px;
  padding-left: 4px;
}

.IY3i {
  display: flex;
  padding-top: 30px;
  padding-left: 16px;
}

.LIcp {
  display: flex;
  padding-top: 32px;
  padding-left: 16px;
}

.sJs1 {
  position: absolute;
  left: 0px;
  font-size: 17px;
  font-weight: 400;
  color: rgba(235, 235, 245, 0.5);
  margin-top: 32px;
}

.IUec {
  position: absolute;
  right: 0px;
  font-size: 17px;
  font-weight: 400;
  color: rgba(235, 235, 245, 0.5);
  margin-top: 32px;
}

.IYwx {
  margin-left: 18px;
}

.Khw3 {
  margin-right: 18px;
}

.JHe8 {
  margin-right: 110px;
}

.YRj2 {
  font-size: 17px;
  font-weight: 400;
}

.ebYk {
  position: absolute;
  left: 0px;
  margin-top: 68px;
  margin-left: 18px;
}

.Ibf1 {
  position: absolute;
  left: 0px;
  margin-top: 68px;
  margin-left: 54px;
  color: #fff;
  font-size: 17px;
  font-weight: 400;
}

.LpsI {
  position: absolute;
  right: 0px;
  margin-top: 68px;
  margin-right: 110px;
}

.IYw2 {
  position: absolute;
  right: 0px;
  margin-top: 68px;
  margin-right: 18px;
}

.IUxe {
  position: absolute;
  left: 0px;
  margin-top: 106px;
  margin-left: 18px;
}

.Ld9i {
  position: absolute;
  left: 0px;
  font-size: 17px;
  font-weight: 400;
  margin-top: 106px;
  margin-left: 54px;
  color: #fff;
}

.Jw4e {
  position: absolute;
  right: 0px;
  margin-top: 106px;
  margin-right: 110px;
}

.UKn3 {
  position: absolute;
  right: 0px;
  margin-top: 106px;
  margin-right: 18px;
}

.Khe8 {
  position: absolute;
  left: 0px;
  font-size: 17px;
  font-weight: 400;
  margin-top: 144px;
  margin-left: 54px;
  color: #fff;
}

.Jwn3 {
  position: absolute;
  left: 0px;
  margin-top: 144px;
  margin-left: 18px;
}

.U0ex {
  position: absolute;
  right: 0px;
  margin-top: 144px;
  margin-right: 110px;
}

.Ieo1 {
  position: absolute;
  right: 0px;
  margin-top: 144px;
  margin-right: 18px;
}

.JUYe {
  position: absolute;
  left: 0px;
  margin-top: 182px;
  margin-left: 18px;
}

.Khd1 {
  position: absolute;
  left: 0px;
  font-size: 17px;
  font-weight: 400;
  margin-top: 182px;
  margin-left: 54px;
  color: #fff;
}

.GFwe {
  position: absolute;
  right: 0px;
  margin-top: 182px;
  margin-right: 110px;
}

.Usyw {
  position: absolute;
  right: 0px;
  margin-top: 182px;
  margin-right: 18px;
}

.w4i3 {
  position: relative;
  height: 38px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #fff6;
  margin-top: 2px;
  cursor: pointer;
}

.Yi28 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 240px;
  border-radius: 20px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.12);
}

.Nzze {
  position: absolute;
  margin-top: 7px;
  margin-left: 16px;
  z-index: 2;
  width: 24px;
  height: 24px;
  background-image: url("./assets/images/demo-ava-8.jpg");
  border-radius: 20px;
  background-size: contain;
}

.nEhw {
  position: absolute;
  margin-top: 7px;
  margin-left: 31px;
  z-index: 4;
  width: 24px;
  height: 24px;
  background-image: url("./assets/images/demo-ava-9.jpg");
  border-radius: 20px;
  background-size: contain;
}

.Fp2m {
  position: absolute;
  margin-top: 7px;
  margin-left: 46px;
  z-index: 6;
  width: 24px;
  height: 24px;
  background-image: url("./assets/images/creator.jpg");
  border-radius: 20px;
  background-size: contain;
}

.jYed {
  position: absolute;
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background-color: rgba(235, 235, 245, 0.6);
}

.IYe4 {
  margin-top: 5px;
  margin-left: 14px;
  z-index: 1;
}

.Kebc {
  margin-top: 5px;
  margin-left: 29px;
  z-index: 3;
}

.IYUe {
  margin-top: 5px;
  margin-left: 44px;
  z-index: 5;
}

.KYci {
  color: var(--palette-transparent-grey-25);
}

.Yevc {
  position: absolute;
  right: 0px;
  margin-top: 10px;
  margin-right: 20px;
}

.J4ec {
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-left: 76px;
}

.JYcc {
  position: absolute;
  right: 0px;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px;
  margin-right: 34px;
  color: rgba(84, 84, 88, 0.45);
}

.Oud1 {
  color: rgba(235, 235, 245, 0.55);
  font-size: 16px;
  font-weight: 400;
  margin-top: 108px;
}

.UYTX {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
}

.Ylhc {
  font-size: 16px;
  font-weight: 400;
  color: rgba(235, 235, 245, 0.55);
}

.Uty3 {
  position: absolute;
  left: 0px;
  margin-top: 332px;
  margin-left: 140px;
  // margin-left: 146px;
}

.Isye {
  position: absolute;
  left: 0px;
  margin-top: 324px;
  // margin-left: 140px;
  margin-left: 152px;
}

.OsUY {
  width: 34px;
  height: 34px;
  border-radius: 20px;
}

.UJxo {
  margin-top: 40px;
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.IUG3 {
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.Rc8S {
  margin-top: 12px;
  border-radius: 14px 14px 0px 0px;
}

.JTc4 {
  border-radius: 0px 0px 14px 14px;
}

.YecU {
  position: fixed;
  bottom: 30px;
  left: 14px;
  right: 14px;
}

.Fie5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-radius: 14px;
  background: linear-gradient(101deg, #ffa800 25.94%, #f47e3c 94.32%);
  box-shadow: 0 2px 28px #00001c0f, 0 0 1px #00001c1f;
  flex-grow: 1;
  cursor: pointer;
  margin-top: 26px;
  margin-bottom: 16px;
}

.demo-ava-13 {
  background-image: url("./assets/images/demo-ava-13.jpg");
  background-size: contain;
}

.demo-ava-1 {
  background-image: url("./assets/images/demo-ava-1.jpg");
  background-size: contain;
}

.creator {
  background-image: url("./assets/images/creator.jpg");
  background-size: contain;
}

.demo-ava-9 {
  background-image: url("./assets/images/demo-ava-9.jpg");
  background-size: contain;
}

.demo-ava-6 {
  background-image: url("./assets/images/demo-ava-6.jpg");
  background-size: contain;
}

.demo-ava-10 {
  background-image: url("./assets/images/demo-ava-10.jpg");
  background-size: contain;
}

.demo-ava-12 {
  background-image: url("./assets/images/demo-ava-12.jpg");
  background-size: contain;
}

.demo-ava-8 {
  background-image: url("./assets/images/demo-ava-8.jpg");
  background-size: contain;
}

.demo-ava-2 {
  background-image: url("./assets/images/demo-ava-2.jpg");
  background-size: contain;
}

// SWIPER

.swiper {
  position: relative;
  overflow: initial !important;
}

.nav-btn {
  position: absolute;
  width: 36px;
  height: 36px;
  transition: background-color ease;
  transition-duration: 0.3s;
  border-radius: 8px;
  &_prev {
    left: 0px;
    transform: translateY(-50%) scaleX(-1);
    transform-origin: center;
    &:active {
      transition-duration: 0s;
      background-color: rgba(235, 235, 245, 0.1);
      border-radius: 8px;
    }
  }
  &_prev-disable {
    left: 0px;
    transform: translateY(-50%) scaleX(-1);
    transform-origin: center;
    cursor: auto;
  }
  &_next {
    right: 0px;
    transform: translateY(-50%);
    &:active {
      transition-duration: 0s;
      background-color: rgba(235, 235, 245, 0.1);
      border-radius: 8px;
    }
  }
  &_next-disable {
    right: 0px;
    transform: translateY(-50%);
    cursor: auto;
  }
}

.mzQ1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 12px;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.12);
  margin-top: 12px;
}

.LibrarySegmentedControl {
  background-color: rgba(255, 255, 255, 0.12);
  border-radius: 14px;
  box-sizing: border-box;
  display: inline-block;
  margin-top: 12px;
  padding: 4px;
  position: relative;
}
.LibrarySegmentedControl--glider .LibrarySegmentedControlItem--active {
  background: transparent;
}
.LibrarySegmentedControl--state-fixed {
  display: flex;
  gap: 4px;
}
.LibrarySegmentedControl--state-fixed .LibrarySegmentedControlItem {
  box-sizing: border-box;
  justify-content: center;
  margin: 0;
  width: 100%;
}
.LibrarySegmentedControl--state-fixed .LibrarySegmentedControlItem__link {
  flex-basis: 100%;
  min-width: 0;
}
.LibrarySegmentedControl__glider {
  background-color: #fff;
  border-radius: 10px;
  box-sizing: border-box;
  display: block;
  height: calc(100% - 8px);
  left: 4px;
  position: absolute;
  top: 4px;
  transition: 0.25s ease-out;
}
.LibrarySegmentedControlItem {
  border-radius: 10px;
  display: inline-flex;
  margin: 0 4px;
  padding: 9px 20px;
}
.LibrarySegmentedControlItem--active {
  background-color: #fff;
}
.LibrarySegmentedControlItem--active .LibrarySegmentedControlItem__inner {
  color: #000 !important;
  font-size: 17px;
  font-weight: 600;
}
@media (hover: hover), (min-width: 768px) {
  .LibrarySegmentedControlItem {
    transition: background-color 0.2s ease-in-out;
  }
  .LibrarySegmentedControlItem:hover:not(.LibrarySegmentedControlItem--active) {
    cursor: pointer;
  }
}
.LibrarySegmentedControlItem__inner {
  color: #fff !important;
  font-size: 17px;
  font-weight: 600;
}
.LibrarySegmentedControlItem__counter {
  margin-left: 8px;
}
.LibrarySegmentedControlItem__link {
  position: relative;
  z-index: 1;
}

.Iy40 {
  // border-radius: 14px;
  backdrop-filter: blur(14px);
  background-color: rgba(255, 255, 255, 0.12);
  // margin-top: 38px;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
  &_with-icon {
    svg {
      flex-shrink: 0;
      color: rgb(112, 117, 121);
    }
  }
}

.Td8x {
  &__header-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px 12px;
    width: 100%;
    // margin-top: 23px;
  }
}

.H3ds {
  &__header-btn-content {
    display: flex;
    align-items: center;
  }
}

.UY3c {
  &__background {
    width: 70px;
    height: 70px;
    background-color: var(--palette-transparent-white-05);
    border-radius: 12px;
    margin-left: 4px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-height: 720px) {
  .Oj3d {
    margin-bottom: 192px;
    // margin-bottom: 160px;
  }
  .Poj3 {
    margin-top: 26px;
  }
  .B3x9 {
    margin-top: 16px;
  }
}

@media screen and (max-height: 720px) and (min-height: 710px) {
  .Oj3d {
    margin-bottom: 192px;
    // margin-bottom: 160px;
  }
  .Poj3 {
    margin-top: 26px;
  }
  .B3x9 {
    margin-top: 12px;
  }
}

@media screen and (max-height: 710px) and (min-height: 700px) {
  .Oj3d {
    margin-bottom: 184px;
    // margin-bottom: 160px;
  }
  .Poj3 {
    margin-top: 26px;
  }
  .B3x9 {
    margin-top: 10px;
  }
}

@media screen and (max-height: 700px) and (min-height: 680px) {
  .Oj3d {
    margin-bottom: 182px;
    // margin-bottom: 150px;
  }
  .Poj3 {
    margin-top: 18px;
  }
  .B3x9 {
    margin-top: 8px;
  }
}

@media screen and (max-height: 680px) and (min-height: 670px) {
  .Oj3d {
    margin-bottom: 172px;
  }
  .Poj3 {
    margin-top: 18px;
  }
  .B3x9 {
    margin-top: 6px;
  }
}

@media screen and (max-height: 670px) and (min-height: 660px) {
  .Oj3d {
    margin-bottom: 168px;
  }
  .Poj3 {
    margin-top: 18px;
  }
  .B3x9 {
    margin-top: 4px;
  }
}

@media screen and (max-height: 660px) and (min-height: 645px) {
  .Oj3d {
    margin-bottom: 164px;
  }
  .Poj3 {
    margin-top: 8px;
  }
  .B3x9 {
    margin-top: 4px;
  }
}

@media screen and (max-height: 645px) and (min-height: 625px) {
  .Oj3d {
    margin-bottom: 152px;
  }
  .Poj3 {
    margin-top: 0px;
  }
  .B3x9 {
    margin-top: 2px;
  }
}

@media screen and (max-height: 625px) and (min-height: 610px) {
  .Oj3d {
    margin-bottom: 146px;
  }
  .Poj3 {
    margin-top: 0px;
  }
  .B3x9 {
    margin-top: 0px;
  }
}

@media screen and (max-height: 610px) and (min-height: 595px) {
  .Oj3d {
    margin-bottom: 146px;
  }
  .Poj3 {
    margin-top: -2px;
  }
  .B3x9 {
    margin-top: -2px;
  }
}

@media screen and (max-height: 595px) and (min-height: 585px) {
  .Oj3d {
    margin-bottom: 136px;
  }
  .Poj3 {
    margin-top: -4px;
  }
  .B3x9 {
    margin-top: -4px;
  }
}

@media screen and (max-height: 585px) and (min-height: 575px) {
  .Oj3d {
    margin-bottom: 126px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

@media screen and (max-height: 575px) and (min-height: 565px) {
  .Oj3d {
    margin-bottom: 116px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

@media screen and (max-height: 565px) and (min-height: 555px) {
  .Oj3d {
    margin-bottom: 106px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

@media screen and (max-height: 555px) and (min-height: 545px) {
  .Oj3d {
    margin-bottom: 96px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

@media screen and (max-height: 545px) and (min-height: 535px) {
  .Oj3d {
    margin-bottom: 86px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

@media screen and (max-height: 535px) and (min-height: 525px) {
  .Oj3d {
    margin-bottom: 76px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

@media screen and (max-height: 525px) and (min-height: 515px) {
  .Oj3d {
    margin-bottom: 66px;
  }
  .Poj3 {
    margin-top: -6px;
  }
  .B3x9 {
    margin-top: -6px;
  }
}

.grid-gap {
  grid-gap: 10px;
}

.root-container {
  position: absolute;
  bottom: 0px;
  left: 14px;
  right: 14px;
}

.clicker {
  width: 300px;
  min-width: 300px;
  height: 300px;
  min-height: 300px;
  border-radius: 50%;
  font-size: 72px;
  font-weight: 700;
  text-align: center;
  line-height: 280px;
  background-size: cover;
  margin: 0 auto;
  transition: transform 0.1s ease-out;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  touch-action: none;
  user-zoom: fixed;
  transform-origin: center;
  will-change: transform;
  position: relative;
  z-index: 1;
  // transition: transform 0.3s;
}

.clicker-position {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  transition: transform 0.1s ease-out;
  will-change: transform;
}

// .clicker {
//   width: 300px;
//   min-width: 300px;
//   height: 300px;
//   min-height: 300px;
//   border-radius: 50%;
//   background-image: url(https://imagedelivery.net/B4lKhBZXBr2ZrOdPiT3vew/e79283e4-6b81-4398-805b-7b24df774900/public);
//   font-size: 72px;
//   font-weight: 700;
//   text-align: center;
//   line-height: 280px;
//   background-size: cover;
//   margin: 0 auto;
//   transition: transform 0.1s ease-out;
//   -webkit-tap-highlight-color: transparent;
//   -webkit-user-select: none;
//   user-select: none;
//   -webkit-user-drag: none;
//   touch-action: none;
//   user-zoom: fixed;
//   transform-origin: center;
//   will-change: transform;
//   position: relative;
//   z-index: 2;
// }

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: transform 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  backface-visibility: hidden;
  will-change: transform;
}

.card {
  &-basic {
    background-image: url("./assets/images/moneta-small.png");
  }
  &-dragon {
    background-image: url("./assets/images/skin-dragon.png");
  }
  &-fallout {
    background-image: url("./assets/images/skin-fallout.png");
  }
  &-galleon {
    background-image: url("./assets/images/skin-galleon.png");
  }
  &-pixelcoin {
    background-image: url("./assets/images/skin-pixelcoin.png");
    border-radius: 50%;
  }
  &-cookie {
    background-image: url("./assets/images/skin-cookie.png");
  }
  &-pizza {
    background-image: url("./assets/images/skin-pizza.png");
  }
  &-telegram {
    background-image: url("./assets/images/skin-telegram.png");
  }
}

.back {
  &-basic {
    background-image: url("./assets/images/moneta-min.png");
  }
  &-dragon {
    background-image: url("./assets/images/skin-dragon-back.png");
  }
  &-fallout {
    background-image: url("./assets/images/skin-fallout-back.png");
  }
  &-galleon {
    background-image: url("./assets/images/skin-galleon-back.png");
  }
  &-pixelcoin {
    background-image: url("./assets/images/skin-pixelcoin-bg-wallet.png");
  }
  &-cookie {
    background-image: url("./assets/images/skin-cookie-back.png");
  }
  &-pizza {
    background-image: url("./assets/images/skin-pizza-back.png");
  }
  &-telegram {
    background-image: url("./assets/images/skin-telegram-back.png");
  }
}

.card-back {
  transform: rotateY(180deg) rotateZ(50deg);
  font-size: 28pt;
  user-select: none;
  line-height: 100px;
  background-size: cover;
  border-radius: 50%;
  will-change: transform;
}

.field-container {
  position: relative;
}

.scale-animation {
  animation: _glow_2szzu_1 1.25s forwards;
  will-change: transform;
}

@keyframes _glow_2szzu_1 {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.field {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  justify-content: center;
  list-style: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  touch-action: none;
  user-zoom: fixed;
  transform-origin: center;
  margin: 0 auto;
  transition: transform 0.1s ease-out;
  will-change: transform;
  // display: grid;
  // grid-template-columns: repeat(3, 1fr);
  // gap: 20px;
  // list-style: none;
  // max-width: 600px;
  // margin: 0 auto;
  &__item {
    width: 100px;
    height: 100px;
    position: relative;
    aspect-ratio: 1/1;
    cursor: pointer;
    .card-back {
      div {
        transform: rotateY(180deg) rotateZ(50deg);
        will-change: transform, visibility, opacity;
        opacity: 0;
        transition-property: opacity, visibility, transform;
        transition-duration: 0.2s;
        transition-delay: 0.3s;
      }
    }
    &.active {
      pointer-events: none;
      .card-front {
        transform: rotateY(180deg) rotateZ(50deg);
        will-change: transform;
      }
      .card-back {
        transform: none;
        div {
          transform: none;
          opacity: 1;
          // visibility: visible;
        }
      }
    }
  }
}

.card-flipped .card-front {
  transform: rotateY(180deg) rotateZ(50deg);
  will-change: transform;
}

.card-flipped .card-back {
  transform: rotateY(0) rotateZ(0);
  will-change: transform;
}

.item-size-ios {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 26px;
  margin-top: 24px;
}

.item-size-material {
  height: 42px;
  width: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 30px;
  margin-top: 28px;
}

.click-amount {
  top: var(--count-y);
  left: var(--count-x);
  position: absolute;
  font-size: 24px;
  line-height: 1;
  font-weight: 700;
  opacity: 0;
  color: #fff;
  -webkit-user-select: none;
  pointer-events: none;
  user-select: none;
  animation: click-amount-animation 0.8s linear forwards;
}

.clicker-amount {
  top: var(--count-y);
  left: var(--count-x);
  position: absolute;
  font-size: 42px;
  line-height: 1;
  font-weight: 700;
  opacity: 0;
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  animation: click-amount-animation 0.8s linear forwards;
}

// .js-big-coin:active,
// .js-field-item:active {
//   animation: _click_rb5hw_114 0.1s ease-in-out;
// }

// @keyframes _click_rb5hw_114 {
//   0% {
//     transform: scale(1);
//   }
//   50% {
//     transform: scale(0.95);
//   }
//   to {
//     transform: scale(1);
//   }
// }

@keyframes click-amount-animation {
  0% {
    transform: translate(-50%, 0);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translate(-50%, -200px);
    opacity: 0;
    visibility: hidden;
  }
}

.star-glitter {
  position: absolute;
  animation: glitter 1.3s ease-in-out 0s infinite alternate;
}
@keyframes glitter {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0.6);
  }
}

.star-animation {
  opacity: 0;
  animation: star-opacity 1s forwards;
}

@keyframes star-opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.stars_squads-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 60px;
  z-index: 1;
  left: 32%;
  transform: translate(-50%), scale(0.6);
}

.stars_squads-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 80px;
  z-index: 1;
  left: 62%;
  transform: translate(-50%), scale(0.6);
}

.stars_squads-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 70px;
  z-index: 1;
  left: 66%;
  transform: translate(-50%), scale(0.6);
}

.stars_party-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 60px;
  z-index: 1;
  left: 16%;
  transform: translate(-50%), scale(0.6);
}

.stars_party-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 140px;
  z-index: 1;
  right: 16%;
  transform: translate(-50%), scale(0.6);
}

.stars_party-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 120px;
  z-index: 1;
  right: 12%;
  transform: translate(-50%), scale(0.6);
}

.stars_stats-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 60px;
  z-index: 1;
  right: 10%;
  transform: translate(-50%), scale(0.6);
}

.stars_stats-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 96px;
  z-index: 1;
  right: 24%;
  transform: translate(-50%), scale(0.6);
}

.stars_stats-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 524px;
  z-index: 1;
  left: 12%;
  transform: translate(-50%), scale(0.6);
}

.stars_stats-4 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 650px;
  z-index: 1;
  right: 6%;
  transform: translate(-50%), scale(0.6);
}

.stars_about-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 560px;
  z-index: 1;
  right: 10%;
  transform: translate(-50%), scale(0.6);
}

.stars_about-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 106px;
  z-index: 1;
  right: 14%;
  transform: translate(-50%), scale(0.6);
}

.stars_about-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 594px;
  z-index: 1;
  left: 12%;
  transform: translate(-50%), scale(0.6);
}

.stars_about-4 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 660px;
  z-index: 1;
  right: 4%;
  transform: translate(-50%), scale(0.6);
}

.stars_earn-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 84px;
  z-index: 1;
  right: 22%;
  transform: translate(-50%), scale(0.6);
}

.stars_earn-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 240px;
  z-index: 1;
  left: 25%;
  transform: translate(-50%), scale(0.6);
}

.stars_earn-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 410px;
  z-index: 1;
  right: 14%;
  transform: translate(-50%), scale(0.6);
}

.stars_earn-4 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 390px;
  z-index: 1;
  right: 5%;
  transform: translate(-50%), scale(0.6);
}

.stars_boost-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 154px;
  z-index: 1;
  right: 22%;
  transform: translate(-50%), scale(0.6);
}

.stars_boost-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 334px;
  z-index: 1;
  left: 25%;
  transform: translate(-50%), scale(0.6);
}

.stars_boost-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 370px;
  z-index: 1;
  right: 14%;
  transform: translate(-50%), scale(0.6);
}

.stars_boost-4 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 340px;
  z-index: 1;
  right: 5%;
  transform: translate(-50%), scale(0.6);
}

.stars_fren-1 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 80px;
  z-index: 1;
  right: 12%;
  transform: translate(-50%), scale(0.6);
}

.stars_fren-2 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 168px;
  z-index: 1;
  right: 25%;
  transform: translate(-50%), scale(0.6);
}

.stars_fren-3 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 550px;
  z-index: 1;
  left: 10%;
  transform: translate(-50%), scale(0.6);
}

.stars_fren-4 {
  position: absolute;
  width: 11px;
  height: 12px;
  top: 660px;
  z-index: 1;
  right: 10%;
  transform: translate(-50%), scale(0.6);
}

.VmE2 {
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  resize: none;
  border: none;
  color: var(--tg-theme-text-color);
  background: rgba(0, 0, 0, 0);
  outline: none;
  padding: 0;
  -webkit-user-select: initial;
  -moz-user-select: initial;
  user-select: initial;
  word-break: break-all;
  overflow-wrap: anywhere;
}

.VmE2:-moz-placeholder-shown {
  word-break: initial;
  overflow-wrap: initial;
}

.VmE2:placeholder-shown {
  word-break: initial;
  overflow-wrap: initial;
}

.VmE2:-moz-placeholder-shown + .pp0Q {
  word-break: initial;
  overflow-wrap: initial;
}

.VmE2:placeholder-shown + .pp0Q {
  word-break: initial;
  overflow-wrap: initial;
}

.VmE2::-moz-placeholder {
  color: var(--tg-theme-subtitle-text-color);
}

.VmE2::placeholder {
  color: var(--tg-theme-subtitle-text-color);
}

.tTP4 {
  animation: yaga 0.5s;
  animation-iteration-count: 1;
}

.yaga-infinity {
  opacity: 0;
  animation: yaga 0.5s infinite, rocket-show-opacity 3s forwards;
}

@keyframes yaga {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.backdrop-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 14px 0;
  border-radius: 14px;
  background-color: #007aff;
  // width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #0661bd;
  }
}

.backdrop-confirm-transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  border-radius: 16px;
  background-color: transparent;
  margin-bottom: 36px;
  width: 100%;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #0661bd;
  }
}

.backdrop-confirm-trade {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 0;
  border-radius: 16px;
  background-color: transparent;
  margin-bottom: 36px;
  width: 100%;
  transition: background-color ease;
  transition-duration: 0.3s;
}

.backdrop-stats-confirm {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  padding: 14px 0;
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 0.12);
  // width: 100%;
  margin-left: 16px;
  margin-right: 16px;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: rgba(255, 255, 255, 0.08);
  }
}

.backdrop-close {
  opacity: 1;
  background-color: none;
  border: none;
  inset-inline-end: 0;
  margin: 0;
  padding: 20px 20px;
  position: absolute;
  top: 0;
  z-index: 1;
  transition: opacity ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    opacity: 0.6;
  }
}

.notification {
  position: fixed;
  bottom: -60px; /* Начальное положение снизу на 20px */
  left: 16px;
  right: 16px;
  transition: 0.25s; /* Анимация движения вниз на 3 секунды */
  z-index: 1001;
  will-change: transition;
}

.notification.visible {
  bottom: 16px; /* Положение внизу */
  will-change: transition;
}

.details {
  position: fixed;
  bottom: -112%; /* Устанавливаем начальное положение за пределами экрана */
  left: 0;
  right: 0;
  transition: 0.35s;
  z-index: 1000;
  will-change: transition;
}

.details.visible {
  bottom: -4px; /* Показываем прямоугольник при добавлении класса visible */
  will-change: transition;
}

.backdrop-style {
  width: 100%;
  border-radius: 25px 25px 0 0;
  background: rgba(255, 255, 255, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.35s;
  transition-duration: 0.35s;
  will-change: transition;
  padding-block: 32px;
  position: relative;
  padding: 0 16px;
  backdrop-filter: blur(48px);
  -webkit-backdrop-filter: blur(48px);
}

.backdrop-stats {
  width: 100%;
  border-radius: 25px 25px 0 0;
  background: rgba(28, 28, 30, 0.99);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.35s;
  transition-duration: 0.35s;
  will-change: transition;
  padding-block: 32px;
  position: relative;
  padding: 0 16px;
  backdrop-filter: blur(74px);
  -webkit-backdrop-filter: blur(74px);
  height: 425px;
}

._sheetFire_g3ht0_262 {
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/images/fire.png");
  animation-name: _flame-a_g3ht0_1;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
  transform-origin: bottom center;
}
._sheetFire_g3ht0_2621 {
  width: 24px;
  height: 24px;
  right: 20px;
  bottom: 120px;
  animation-duration: 2.3s;
}
._sheetFire_g3ht0_2622 {
  width: 44px;
  height: 44px;
  right: 48px;
  bottom: 75px;
  animation-delay: 0.5s;
  animation-duration: 3.5s;
  z-index: 1000;
}
._sheetFire_g3ht0_2623 {
  width: 20px;
  height: 20px;
  right: 100px;
  bottom: 90px;
  animation-delay: 1s;
  animation-duration: 2.8s;
}

._flame_g3ht0_312 {
  position: absolute;
  transform-origin: 70% 70%;
  z-index: 2;
  display: inline-block;
  width: 44px;
  height: 44px;
  right: 48px;
  bottom: 75px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("./assets/images/fire.png");
}
._flame_g3ht0_312._anima_g3ht0_326 {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: _flame-animation_g3ht0_1;
  opacity: 0;
  z-index: 1;
  animation-timing-function: ease-in-out;
}

@keyframes _flame-a_g3ht0_1 {
  0% {
    transform: scale(1) scale(1);
  }
  50% {
    transform: scale(1) scale(1.2);
  }
  to {
    transform: scale(1) scale(1);
  }
}
@keyframes _flame-animation_g3ht0_1 {
  0%,
  to {
    opacity: 0;
    transform: translateZ(0) scale(0.75) rotate(0) scale(1);
  }
  50% {
    opacity: 0.35;
    transform: translate3d(0, -30%, 0) scale(1) rotate(-2deg) scale(1.05);
  }
  99% {
    opacity: 0;
    transform: translate3d(0, -75%, 0) scale(0.8) rotate(0) scale(1);
  }
}
