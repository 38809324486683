.grey-transparent-btn {
  border-radius: 14px;
  backdrop-filter: blur(14px);
  background-color: rgba(255, 255, 255, 0.12);
  margin-top: 38px;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
  &_with-icon {
    svg {
      flex-shrink: 0;
      color: rgb(112, 117, 121);
    }
  }
}

.earn-transparent-div {
  position: relative;
  border-radius: 14px;
  background: rgba(255, 255, 255, 0.12);
  &_first {
    padding: 51px;
    margin-top: 14px;
  }
  &_second {
    padding: 142px;
    margin-top: 14px;
  }
  &_third {
    padding: 96px;
    margin-top: 14px;
  }
}

.earn-transparent-btn {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 90px;
  width: 100%;
  transition: background-color ease;
  transition-duration: 0.3s;
  &_block {
    position: absolute;
    background: transparent;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 90px;
    width: 100%;
    cursor: auto;
    // opacity: 0.6;
  }
  &_first {
    margin-top: 6px;
    border-radius: 4px;
  }
  &_second {
    margin-top: 6px;
    border-radius: 4px 4px 0px 0px;
  }
  &_third {
    margin-top: 96px;
  }
  &_fourth {
    margin-top: 186px;
    border-radius: 0px 0px 4px 4px;
  }
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
}

.earn-page {
  &__header-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 16px 12px;
    width: 100px;
    margin-top: 23px;
  }
  &__header-btn-content {
    display: flex;
    align-items: center;
  }
}

.IconBackground {
  width: 70px;
  height: 70px;
  background-color: var(--palette-transparent-white-05);
  border-radius: 12px;
  margin-left: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

// .earn-icon {
//   font-size: 36px;

//   &__background {
//     width: 70px;
//     height: 70px;
//     background-color: var(--palette-transparent-white-05);
//     border-radius: 12px;
//     margin-left: 2px;
//     position: relative;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }

.earn-icon {
  margin-top: 30px;
  width: 100px;
  height: 100px;
  background-image: url("../../assets/images/moneta-small.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.earn-animation {
  opacity: 0; /* Начальная прозрачность */
  animation: earn-opacity 0.5s forwards; /* Название анимации и время */
}

@keyframes earn-opacity {
  from {
    opacity: 0; /* Начальное значение прозрачности */
  }
  to {
    opacity: 1; /* Конечное значение прозрачности */
  }
}

.task-loader {
  width: 16px;
  min-width: 16px;
  height: 16px;
  min-height: 16px;
  border: 2.6px solid rgba(235, 235, 245, 0.18);
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: task-rotation 1.5s linear infinite;
}

@keyframes task-rotation {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

// .check-mark {
//   width: 16px;
//   height: 16px;
//   background-image: url("../../assets/images/pngfind.com-check-png-1223784.png");
//   background-repeat: no-repeat;
//   background-size: contain;
//   color: rgba(235, 235, 245, 0.3);
// }

.send-btn {
  position: relative;
  width: 100%;
  border-radius: 16px;
  height: 50px;
  padding: 33px 12px;
  background-color: rgba(255, 255, 255, 0.12);
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
}

.receive-btn {
  position: relative;
  width: 100%;
  border-radius: 16px;
  height: 50px;
  padding: 33px 12px;
  background-color: rgba(255, 255, 255, 0.12);
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  transition: background-color ease;
  transition-duration: 0.3s;
  &:active {
    transition-duration: 0s;
    background-color: #29292c;
  }
}

.skin-pixelcoin-bg {
  background-image: url("../../assets/images/skin-pixelcoin-bg-wallet.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}

.skin-dragon-bg {
  background-image: url("../../assets/images/dragon_coin_with_gradient.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
}
